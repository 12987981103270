import React from 'react'
import '../stylesheets/SocialLinks.css'

const SocialLinks = () => (
  <nav className='SocialLinks'>
    <ul>
      {/* <li>
        <a
          href='https://www.facebook.com/FragranceCreators'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/img/facebook.svg' alt='Facbook logo' />
        </a>
      </li> */}
      <li>
        <a
          href='https://www.instagram.com/fragranceconservatory/'
          target='_blank'
          rel='noopener noreferrer'>
          <img
            src='/img/instagram.svg'
            alt='Instagram logo' />
        </a>
      </li>
      {/* <li>
        <a
          href='https://twitter.com/Make_Scents'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/img/twitter.svg' alt='Twitter logo' />
        </a>
      </li> */}
      <li>
        <a
          href='https://www.linkedin.com/company/international-fragrance-association-north-america/'
          target='_blank'
          rel='noopener noreferrer'>
          <img src='/img/linkedin.svg' alt='LinkedIn logo' />
        </a>
      </li>
    </ul>
  </nav>
)

export default SocialLinks
