import React, { Component } from 'react';
import MemberItem from '../components/MemberItem';
import Loader from '../components/Loader';
import PageHeader from '../components/PageHeader';
import shuffle from 'lodash/shuffle';
import { SanityContext } from '../App';

class MembersPage extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
    const { sanityClient } = this.props;
    const query = `*[_type == 'member']{...}[0..500]`;

    sanityClient.fetch(query).then(result => {
      this.setState({
        members: result,
      });
    });
  }
  render() {
    const { members } = this.state;

    return (
      <div className="container">
        <PageHeader
          document={{
            lead:
              'Welcome to the future home of the Fragrance Creators Member Center. We are overhauling our Member Center to create a streamlined platform that will provide targeted, valuable members-only content and tools that help you excel in your job and further engage with the organization and the industry as a whole. We will be distributing a comprehensive member survey this year and look forward to hearing your feedback and input on what sort of tools and information would be most impactful for you. Share your input or questions now.',
            title: 'Member Center',
          }}
        />
        {members ? (
          <div className="row" style={{ marginBottom: '8rem' }}>
            <div className="col-12"></div>
            {shuffle(members).map(member => (
              <MemberItem {...member} key={member._id} />
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <MembersPage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
