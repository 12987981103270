import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { client } from '../utils/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

const imageUrl = imageUrlBuilder(client)

class Metadata extends Component {
  state = {
    meta: {}
  }

  getImageUrl = (image) => (
    imageUrl.image(image).width(1200).height(630).quality(80).fit('crop').url()
  )

  getDescription = (desc) => {
    if (desc.constructor.name === 'Array') {
      // It's block content - extract text strings
      return desc
        .map(block => block.children
          .filter(child => child._type === 'span')
          .map(child => child.text)
          .join('')
        )
    } else {
      // It's a string - just return it
      return desc || ''
    }
  }

  render() {
    const { description, title, image, location, status } = this.props
    return (
      <Helmet>
        <title>{title || 'Fragrance Creators Association'}</title>
        <meta
          property='description'
          content={this.getDescription(description || '')} />
        <meta
          property='og:type'
          content='website' />
        <meta
          property='og:title'
          content={title || ''} />
        <meta
          property='og:description'
          content={this.getDescription(description || '')} />
        <meta
          property='og:url'
          content={`https://fragrancecreators.org${location ? location.pathname : ''}`} />
        <meta
          property='og:image'
          content={image ? this.getImageUrl(image) : 'https://cdn.sanity.io/images/vzmfjgad/production/41ce9d0b7912e5905508ca54ced1fd02ec6eb971-800x800.jpg?w=1200&h=630&fit=crop'} />
        <meta
          name='twitter:title'
          content={title || ''} />
        <meta
          name='twitter:description'
          content={this.getDescription(description || '')} />
        <meta
          name='twitter:image'
          content={image ? this.getImageUrl(image) : 'https://cdn.sanity.io/images/vzmfjgad/production/41ce9d0b7912e5905508ca54ced1fd02ec6eb971-800x800.jpg?w=1200&h=630&fit=crop'} />
        <meta
          property='og:image:width'
          content='1200' />
        <meta
          property='og:image:height'
          content='630' />
        <meta
          name='prerender-status-code'
          content={status || 200} />
      </Helmet>
    )
  }
}

export default Metadata
