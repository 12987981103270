import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import '../stylesheets/CardList.css';

const CardList = ({ cards, heading, onClick }) => {
  return (
    <div
      className={classNames('CardList', {
        'CardList--center': cards && cards.length < 3,
      })}
    >
      {heading && <h2 className="CardList__heading">{heading}</h2>}
      <ul className="CardList__entries">
        {cards.map((card, i) => (
          <li key={i} className="CardList__entry">
            <Link to={card.link} className="CardList__border" onClick={onClick}>
              <div className="CardList__title-container">
                <h3 className="CardList__title">{card.title}</h3>
              </div>
              <p className="CardList__read-more Button Button--wide btn btn-outline-dark">
                Read more
              </p>
              <svg
                className="CardList__arrow"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="109px"
                height="162.4px"
                viewBox="0 0 109 162.4"
                xmlSpace="preserve"
              >
                <g transform="translate(0, -201)">
                  <g transform="translate(-139, -318)">
                    <polygon
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      points="193.5,680 247,626.4 247,599.5 203,643.7 203,520 184,520 184,643.7 140,599.5 140,626.4"
                    ></polygon>
                  </g>
                </g>
              </svg>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CardList;
