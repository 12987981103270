import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { AppWithRouter } from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import lazysizes from 'lazysizes';

// A polyfill hack to ensure ie11 support for now.
// polyfill({
//   fills: ['es6', 'Promise', 'Array.prototype.includes', 'Array.prototype.find'],
//   options: ['gated'],
//   minify: false,
//   rum: false,
//   afterFill() {
//     lazysizes.init();

//     ReactDOM.render(
//       <BrowserRouter>
//         <AppWithRouter />
//       </BrowserRouter>,
//       document.getElementById('root')
//     );

//     registerServiceWorker();
//   },
// });

lazysizes.init();

ReactDOM.render(
  <BrowserRouter>
    <AppWithRouter />
  </BrowserRouter>,
  document.getElementById('root')
);

registerServiceWorker();
