import React, { PureComponent } from 'react';
import MiscPage from './MiscPage';
import ArticlePage from './ArticlePage';
import Loader from '../components/Loader';
import { SanityContext } from '../App';

// *****
// This component looks at the document type from Sanity and assigns
// a rendering component accordingly.

class DocumentTypeSwitcher extends PureComponent {
  state = {
    documentType: null,
    ComponentToRender: null,
  };

  componentDidMount() {
    const { match } = this.props;
    const slug = match.params.slug;
    this.getData(slug);
  }

  // Catch navigation that uses the same component
  componentDidUpdate(prevProps) {
    this.getData(this.props.match.params.slug);
  }

  getData(slug) {
    const { history, sanityClient } = this.props;

    const componentsMap = {
      miscPage: MiscPage,
      news: ArticlePage,
      event: ArticlePage,
      currentAffairsArticle: ArticlePage,
    };

    const query = `*[_type in [
      'miscPage',
      'news',
      'event',
      'currentAffairsArticle'
    ] && slug.current == '${slug}']{_type, _id}[0]`;

    if (sanityClient) {
      sanityClient.fetch(query).then(result => {
        if (result) {
          this.setState({
            documentType: result._type,
            documentId: result._id,
            ComponentToRender: componentsMap[result._type],
          });
        } else {
          history.push('/404');
        }
      });
    }
  }

  render() {
    const { documentId, ComponentToRender } = this.state;
    return (
      <React.Fragment>
        {ComponentToRender ? (
          <ComponentToRender id={documentId} {...this.props} />
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => (
      <DocumentTypeSwitcher {...props} sanityClient={context.client} />
    )}
  </SanityContext.Consumer>
);
