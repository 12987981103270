import React, { PureComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { SanityContext } from '../App';

import Metadata from '../components/Metadata';
import Loader from '../components/Loader';
import NewsItem from '../components/NewsItem';

class NewsPage extends PureComponent {
  state = {
    newsItemsRange: 100,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const { sanityClient } = this.props;
    const query = `*[_type == 'news']{
      title,
      slug,
      _id,
      _createdAt,
      publishedAt,
      "_publishedAt": coalesce(publishedAt, _createdAt),
      lead,
      headerImage
    } | (order publishedAt desc) [0..1000]`;

    if (sanityClient) {
      sanityClient.fetch(query).then(result => {
        this.setState(
          {
            news: result,
          },
          () => {
            const hash = this.props.location.hash.slice(1);
            if (hash) {
              const element = document.getElementById(`NewsItem-${hash}`);

              if (element) {
                setTimeout(() => {
                  const rect = element.getBoundingClientRect();
                  window.scrollTo({
                    left: 0,
                    top: rect.top - 150,
                    behavior: 'smooth',
                  });
                }, 100);
              }
            }
          }
        );
      });
    }
  }

  showNewsItems = () => {
    this.setState({
      newsItemsRange: this.state.newsItemsRange + 100,
    });
  };

  render() {
    const { news, newsItemsRange } = this.state;

    return (
      <div className="container">
        <Metadata
          title={`News`}
          description={document.lead || []}
          location={this.props.location}
        />
        {news ? (
          <section>
            <h1 className="NewsPageTitle display-4">News</h1>
            <TransitionGroup className="NewsList row">
              {news.slice(0, newsItemsRange).map((item, index) => {
                item.index = index;
                item.featured = index === 0;

                return (
                  <CSSTransition
                    key={item._id + index}
                    timeout={500}
                    classNames="fade"
                  >
                    <NewsItem {...item} />
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className={
                  'Button btn btn-outline-dark btn-lg ' +
                  (newsItemsRange > news.length ? 'invisible' : '')
                }
                onClick={() => this.showNewsItems()}
              >
                Show more news
              </button>
            </div>
          </section>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <NewsPage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
