import React from 'react';
import { client } from '../utils/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import get from 'lodash/get';
import classNames from 'classnames';

const sanity = client;
const imageUrl = imageUrlBuilder(sanity);

// This component uses lazysizes (https://github.com/aFarkas/lazysizes)
// to build responsive low quality placeholder images
// with blur up technique.
const BaseImage = props => {
  const { image, width, ratio, node } = props;

  // Sanity url builder needs the _ref property,
  // but when using a projection query we only get the _id
  if (image._id) {
    image._ref = image._id;
  }

  // If we haven't set a desired aspect ratio
  // then use the original image's aspect ratio (must ask for this specifically
  // in the query).
  const _ratio =
    ratio || get(image, 'metadata.dimensions.aspectRatio') || 3 / 4;

  const containerStyle = {
    paddingBottom: _ratio * 100 + '%',
  };

  return (
    <div
      className={classNames('ratio-box', {
        'ratio-box--small': node && node.isSmall,
      })}
    >
      <div className="ratio-box__inner" style={containerStyle} />
      <img
        src={imageUrl
          .image(image)
          .width(Math.round(width / 5))
          .height(Math.round((width * _ratio) / 5))
          .fit('crop')
          .url()}
        data-src={imageUrl
          .image(image)
          .width(width)
          .height(Math.round(width * _ratio))
          .fit('crop')
          .quality(80)
          .url()}
        data-srcset={
          imageUrl
            .image(image)
            .width(700)
            .height(Math.round(700 * _ratio))
            .fit('crop')
            .quality(80)
            .url() +
          ' 400w, ' +
          imageUrl
            .image(image)
            .width(Math.round(width / 2))
            .height(Math.round((width / 2) * _ratio))
            .fit('crop')
            .quality(80)
            .url() +
          ' 800w, ' +
          imageUrl
            .image(image)
            .width(width)
            .height(Math.round(width * _ratio))
            .fit('crop')
            .quality(80)
            .url() +
          ' 1200w'
        }
        alt={(node && node.caption) || ''}
        className="lazyload blurUp"
      />
    </div>
  );
};

export default BaseImage;
