import React, { Component } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Loader from '../components/Loader';
import ArticleHeader from '../components/ArticleHeader';
import Metadata from '../components/Metadata';
import { SanityContext } from '../App';
import BlockDocument from '../components/BlockDocument';
import BlockImage from '../components/BlockImage';
import '../stylesheets/BlockContent.css';
import '../stylesheets/ArticlePage.css';

class ArticlePage extends Component {
  state = {
    document: null,
  };

  serializers = {
    types: {
      document: BlockDocument,
      image: BlockImage,
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { sanityClient } = this.props;

    var query = `
      *[_id == '${this.props.id}']{
      ...,
      "_publishedAt": coalesce(publishedAt, _createdAt),
      body[] {
        ...,
        members[] {
          role,
          person->{...}
        },
        file{asset->{url}},
        asset->{_id, metadata{dimensions{aspectRatio}}}
      }
    }[0]`;

    sanityClient.fetch(query).then(result => {
      this.setState({
        document: result,
      });
    });
  }

  render() {
    const { document } = this.state;
    return (
      <article className="container ArticlePage">
        {document ? (
          <React.Fragment>
            <Metadata
              title={document.title}
              description={document.lead || []}
              image={document.headerImage}
              location={this.props.location}
            />
            <ArticleHeader {...document} />
            <div className="row">
              <div
                className="col-md-8 offset-md-2"
                style={{ marginBottom: '10rem' }}
              >
                <div className="BlockContent">
                  <BlockContent
                    blocks={document.body || []}
                    serializers={this.serializers}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </article>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <ArticlePage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
