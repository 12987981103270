import React from 'react'
import BaseImage from './BaseImage'
import BlockContent from '@sanity/block-content-to-react'
import moment from 'moment'

const ArticleHeader = (props) => {
  return (
    <header className='NewsItem row' style={{marginTop: '5rem'}}>
        <div className={'NewsItem-image col-md-4 offset-md-1 ' + (props.index % 2 === 1 ? 'order-md-last' : '')}>
          {
            props.headerImage &&
              <BaseImage
                image={props.headerImage}
                ratio={1.3}
                width={400}
                />
          }
        </div>
        <div className={'col-md-4 ' + (props.index % 2 === 1 ? 'offset-md-2' : 'order-md-last offset-md-1')}>
          <p>
            <small>{moment(props.startTime || props._publishedAt || '').format('MMMM D, YYYY')}</small>
          </p>
          <h1>{props.title || ''}</h1>
          <BlockContent blocks={props.lead || []} />
        </div>
    </header>
  )
}

export default ArticleHeader
