import React from 'react';
import classNames from 'classnames';
import { client } from '../utils/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import '../stylesheets/PageHeader.css';

const imageUrl = imageUrlBuilder(client);

const PageHeader = props => {
  const { document, kicker, children } = props;

  return (
    <header
      className={classNames('PageHeader', {
        'PageHeader--noImage': !document.headerImage,
        'PageHeader--plain': props.plain,
      })}
      style={{
        backgroundImage:
          document.headerImage &&
          `url(${imageUrl
            .image(document.headerImage)
            .width(2000)
            .quality(80)
            .url()})`,
      }}
    >
      <div className="PageHeader__text container">
        <h1 className="PageHeader__heading">
          {kicker && <span className="Kicker">{kicker}</span>}
          {document.title}
        </h1>
        {document.lead && (
          <div className="PageHeader__lead ">
            {// Check if the lead is a array of blocks
            // or just a simple string
            document.lead && document.lead.constructor.name === 'Array' ? (
              <BlockContent blocks={document.lead} />
            ) : (
              <p>{document.lead}</p>
            )}
          </div>
        )}
        {children}
      </div>
    </header>
  );
};

export default PageHeader;
