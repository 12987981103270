import React, { PureComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import moment from 'moment';

import { SanityContext } from '../App';

import Metadata from '../components/Metadata';
import Loader from '../components/Loader';
import NewsItem from '../components/NewsItem';

class EventsPage extends PureComponent {
  state = {
    eventItemsRange: 100,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { sanityClient } = this.props;
    const query = `*[_type == 'event' && startTime > '${moment().toISOString()}']{
      title,
      slug,
      _id,
      startTime,
      lead,
      headerImage
    } | (order startTime asc) [0..1000]`;

    if (sanityClient) {
      sanityClient.fetch(query).then(result => {
        this.setState(
          {
            events: result,
          },
          () => {
            const hash = this.props.location.hash.slice(1);
            if (hash) {
              const element = document.getElementById(`NewsItem-${hash}`);

              if (element) {
                setTimeout(() => {
                  const rect = element.getBoundingClientRect();
                  window.scrollTo({
                    left: 0,
                    top: rect.top - 150,
                    behavior: 'smooth',
                  });
                }, 100);
              }
            }
          }
        );
      });
    }
  }

  showMore = () => {
    this.setState({
      eventItemsRange: this.state.eventItemsRange + 100,
    });
  };

  render() {
    const { events, eventItemsRange } = this.state;

    return (
      <div className="container">
        <Metadata
          title={`Events`}
          description={document.lead || []}
          location={this.props.location}
        />
        {events ? (
          <section>
            <h1 className="NewsPageTitle display-4">Events</h1>
            <TransitionGroup className="NewsList row">
              {events.slice(0, eventItemsRange).map((item, index) => {
                item.index = index;
                item.featured = index < 3;

                return (
                  <CSSTransition
                    key={item._id + index}
                    timeout={500}
                    classNames="fade"
                  >
                    <NewsItem {...item} />
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className={
                  'Button btn btn-outline-dark btn-lg ' +
                  (eventItemsRange > events.length ? 'invisible' : '')
                }
                onClick={() => this.showMore()}
              >
                Show more events
              </button>
            </div>
          </section>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <EventsPage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
