import React, { Component } from 'react'
import BaseImage from './BaseImage'
import '../stylesheets/BlockPerson.css'
import BlockContent from '@sanity/block-content-to-react'

class Person extends Component {
  constructor(props) {
    super(props)
    this.textField = React.createRef()
  }

  state = {}

  componentDidMount() {
    this.setState({
      // Is the text content larger than the container?
      // Used to conditionally render read more button
      isOverflown: this.textField.current.clientHeight < this.textField.current.scrollHeight
    })
  }

  render() {
    const { person, isOdd, role } = this.props
    return (
      <div className='BlockPerson row'>
        <div className={'BlockPerson__image col-md-4 col-10 offset-1 ' + (isOdd ? 'order-md-last offset-md-1' : 'offset-md-1')}>
          {
            person.photo &&
              <BaseImage
                image={person.photo}
                ratio={1.2}
                width={700}
              />
          }
        </div>
        <div
          ref={this.textField}
          className={'BlockPerson__text col-md-4 col-10 offset-1 hide-overflow '
            + (isOdd ? ' offset-md-2 ' : ' order-last offset-md-1 ')
            + (!this.state.isOverflown && ' no-overflow ')}>
          { role && <p>{role || ''}</p> }
          <h5>{person.fullName}</h5>
          <p><strong>{person.title || ''}</strong></p>
          <BlockContent blocks={person.biography || []} />
          {
            this.state.isOverflown &&
              <div className='BlockPerson__moreButton' onClick={(e) => {
                this.textField.current.classList.remove('hide-overflow')
                e.currentTarget.classList.add('invisible')
              }}>
                <p>Read more</p>
              </div>
          }
        </div>
      </div>
    )
  }
}

export default Person
