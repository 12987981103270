import React, { PureComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { SanityContext } from '../App';

import debounce from 'lodash/debounce';

import Metadata from '../components/Metadata';
import Loader from '../components/Loader';
import NewsItem from '../components/NewsItem';

import '../stylesheets/SearchPage.scss';

class SearchPage extends PureComponent {
  state = {
    search: '',
  };

  constructor(props) {
    super();

    this.state.search = props.match.params.query;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.search();
  }

  search() {
    const search = this.state.search;
    const { sanityClient } = this.props;
    const query = `*[
        title match "${search}*" ||
        fullName match "${search}*" ||
        lead[].children[].text match "${search}*" ||
        body[].children[].text match "${search}*"
      ] {
        ...,
        "_publishedAt": coalesce(publishedAt, _createdAt),
      }`;

    if (sanityClient) {
      sanityClient.fetch(query).then(results => {
        if (!this.state.search) {
          this.setState({
            results: [],
          });
          return;
        }

        const sortedResults = results
          .filter(result => !!result.slug)
          .sort((a, b) => {
            const aTitle = (a.title || '').toLowerCase();
            const bTitle = (b.title || '').toLowerCase();
            let aLead = '';
            if (Array.isArray(a.lead)) {
              for (const entry of a.lead) {
                if (Array.isArray(entry.children)) {
                  for (const child of entry.children) {
                    if (child.text) {
                      aLead += child.text.toLowerCase();
                    }
                  }
                }
              }
            }

            if (aTitle.includes(search) && bTitle.includes(search)) {
              return new Date(b.publishedAt) - new Date(a.publishedAt);
            } else if (aTitle.includes(search)) {
              return -1;
            } else if (aLead.includes(search)) {
              return -1;
            }

            return new Date(b.publishedAt) - new Date(a.publishedAt);
          });

        this.setState({
          results: sortedResults,
        });
      });
    }
  }

  debounceSearch = debounce(query => {
    if (query) {
      this.search(query);
    } else {
      this.setState({
        results: [],
      });
    }
  }, 350);

  handleChange = e => {
    const val = e.target.value;
    this.setState({
      search: val,
    });

    this.debounceSearch(val);
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    const { results, search } = this.state;

    return (
      <div className="container">
        <Metadata
          title={`Search`}
          description={document.lead || []}
          location={this.props.location}
        />
        {results ? (
          <section className="SearchPage">
            <div className="display-4">
              <h1 className="NewsPageTitle ">
                <form onSubmit={this.handleSubmit}>
                  <input
                    autoFocus
                    type="search"
                    placeholder="Type to search"
                    value={search}
                    onChange={this.handleChange}
                  />
                </form>
              </h1>
              <p className="SearchPage__results-label">
                {search
                  ? `${results.length} result(s) for "${search}"`
                  : `Type above to search`}
              </p>
            </div>

            <TransitionGroup className="NewsList row SearchPage__results">
              {results.map((item, index) => {
                item.index = index;
                item.featured = index === 0;

                return (
                  <CSSTransition
                    key={item._id + index}
                    timeout={500}
                    classNames="fade"
                  >
                    <NewsItem {...item} />
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </section>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <SearchPage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
