import React, { Component } from 'react'
import { client } from '../utils/sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '@sanity/block-content-to-react'
import '../stylesheets/PageHeaderWithVideo.css'

const imageUrl = imageUrlBuilder(client)

/*
  This component now has a lot of expections in
  order to cater for usage with and without text on top.
  And fallback to no video on mobile.
  Should be cleaned up.
*/
class PageHeaderWithVideo extends Component {

  state = {}

  componentDidMount() {
    var mobileDetect = require('mobile-detect')
    var md = new mobileDetect(window.navigator.userAgent)
    this.setState({
      isMobile: md.phone()
    })
  }

  render() {
    const props = this.props
    return (
      <header className='PageHeaderWithVideo'>
        {
           !this.state.isMobile &&
           <div className='PageHeaderWithVideo__videoContainer'>
              <video
                id="video"
                src={props.video.asset.url}
                autoPlay='autoplay'
                muted='muted'
                className='d-none d-xs-none d-sm-none d-md-block d-lg-block'
              >
              </video>
            </div>
        }

          <div className='d-sm-block d-md-none' style={
              {
                backgroundImage: `url(${imageUrl.image(props.image).width(750).url()})`,
                height: 'calc(100vh - 57px)',
                width: 'calc(100% + 2px)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }
            }>
        </div>
        <div className={'PageHeaderWithVideo__text ' + (props.frontpageTitle ? 'no-line' : '')}>
          <div>
            <h1>{props.title || ''}</h1>
            <BlockContent blocks={props.lead || []} />
            <h1 className='d-sm-block d-md-none'>{props.frontpageTitle || ''}</h1>
            <h4 className='d-sm-block d-md-none'>{props.frontpageSubtitle || ''}</h4>
          </div>

        </div>
        <div className='PageHeaderWithVideo__decorLine'></div>
      </header>
    )
  }
}

export default PageHeaderWithVideo
