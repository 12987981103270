import React, { Component } from 'react';
import MemberItem from './MemberItem';
import { client } from '../utils/sanityClient';
import shuffle from 'lodash/shuffle';

class BlockMembers extends Component {
  state = {
    members: null,
  };

  componentDidMount() {
    const query = `*[_type == 'member']{...}[0..500]`;
    client.fetch(query).then(result => {
      this.setState({
        members: result,
      });
    });
  }

  render() {
    const { members } = this.state;
    const { title } = this.props.node;
    return (
      members && (
        <section className="row" style={{ marginBottom: '8rem' }}>
          <div className="col-12">
            <h2 className="display-4">{title}</h2>
          </div>
          {shuffle(members).map(member => (
            <MemberItem {...member} key={member._id} />
          ))}
        </section>
      )
    );
  }
}

export default BlockMembers;
