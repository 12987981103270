import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import moment from 'moment';
import classNames from 'classnames';

import { SanityContext } from '../App';

import Loader from '../components/Loader';
import PageHeader from '../components/PageHeader';
import NewsItem from '../components/NewsItem';
import Timeline from '../components/Timeline';
import Metadata from '../components/Metadata';

// import BlockContent from '@sanity/block-content-to-react';
import BlockDocument from '../components/BlockDocument';
import BlockImage from '../components/BlockImage';
import CardList from '../components/CardList';
import DocumentTypeSwitcher from './DocumentTypeSwitcher';

import '../stylesheets/BlockContent.css';
import '../stylesheets/CurrentAffairsPage.scss';

import '../stylesheets/Tabs.scss';

class CurrentAffairsPage extends Component {
  state = {
    newsItemsRange: 1,
  };

  serializers = {
    types: {
      document: BlockDocument,
      image: BlockImage,
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const { sanityClient } = this.props;
    const query = `*[_type == 'currentAffairsPage'] {
      title,
      timeline[] {
        ...,
        internalLink->{
          _type,
          slug,
        },
      },
      tab1 {
        name,
        body[] {
          ...,
          members[] {
            role,
            person->{...}
          },
          file{asset->{url}},
          asset->{_id, metadata{dimensions{aspectRatio}}}
        },
        "tags": tags[]-> {
            _id,
            "news": *[_type == 'news' || _type == 'currentAffairsArticle' && references(^._id)] {
              _type,
              title,
              slug,
              _id,
              _createdAt,
              publishedAt,
              externalLink,
              "_publishedAt": coalesce(publishedAt, _createdAt),
              lead,
              headerImage
            } | (order publishedAt desc)
        }
      },
      tab2 {
        name,
        body[] {
          ...,
          members[] {
            role,
            person->{...}
          },
          file{asset->{url}},
          asset->{_id, metadata{dimensions{aspectRatio}}}
        },
        "tags": tags[]-> {
            _id,
            "news": *[_type == 'news' && references(^._id)] {
              _type,
              title,
              slug,
              _id,
              _createdAt,
              publishedAt,
              "_publishedAt": coalesce(publishedAt, _createdAt),
              lead,
              headerImage
            } | (order publishedAt desc)
        }
      },
      "resources": *[_type == 'currentAffairsCategory'] {
        ...,
        "news": *[_type == 'currentAffairsArticle' && references(^._id)] {
          _type,
          title,
          slug,
          externalLink,
          _id,
          _createdAt,
          publishedAt,
          "_publishedAt": coalesce(publishedAt, _createdAt),
          lead,
          headerImage
        } | (order publishedAt desc)
      },
    }[0]`;

    const sortNews = tab => {
      if (!tab) return [];

      let news = [];
      if (Array.isArray(tab.tags)) {
        for (const tag of tab.tags) {
          if (tag.news && Array.isArray(tag.news)) {
            news = tag.news.sort((a, b) => {
              return new Date(b.publishedAt) - new Date(a.publishedAt);
            });
          }
        }
      }

      return news;
    };

    const sortTimeline = news => {
      return news
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        })
        .map(entry => {
          entry.date = moment(entry.date).format('MMM DD');
          return entry;
        });
    };

    const sortResources = resources => {
      return resources.sort((a, b) => {
        return (
          (a.order === undefined ? 999 : a.order) -
          (b.order === undefined ? 999 : b.order)
        );
      });
    };

    sanityClient.fetch(query).then(result => {
      result.tab1.news = sortNews(result.tab1);
      // result.tab2.news = sortNews(result.tab2);

      this.setState({
        ...result,
        timeline: sortTimeline(result.timeline),
        resources: sortResources(result.resources),
      });
    });

    document.body.classList.add('currentaffairspage');
  }

  componentWillUnmount() {
    document.body.classList.remove('currentaffairspage');
  }

  showNewsItems = () => {
    this.setState({
      newsItemsRange: this.state.newsItemsRange + 10,
    });
  };

  render() {
    const {
      title,
      timeline,
      tab1,
      // tab2,
      newsItemsRange,
      resources,
    } = this.state;

    return (
      <>
        <Metadata title={title} image={null} location={this.props.location} />

        {title ? (
          <Switch>
            <Route
              exact
              path="/covid-19"
              render={() => (
                <div className="animate">
                  <div className="container CurrentAffairsPage">
                    <PageHeader
                      document={{
                        title,
                      }}
                      plain
                    />
                  </div>
                  {timeline && timeline.length > 0 && (
                    <Timeline entries={timeline} />
                  )}
                  <div
                    className="container CurrentAffairsPage"
                    style={{
                      marginBottom: '8rem',
                    }}
                  >
                    <TransitionGroup className="NewsList row">
                      {Array.isArray(tab1.news) &&
                        tab1.news
                          .slice(0, newsItemsRange)
                          .map((item, index) => {
                            // if (newsItemsRange === 1) {
                            item.index = index;
                            // } else {
                            // item.index = index + 1;
                            // }
                            item.featured = index === 0;

                            return (
                              <CSSTransition
                                key={item._id + index}
                                timeout={500}
                                classNames="fade"
                              >
                                <NewsItem {...item} />
                              </CSSTransition>
                            );
                          })}
                    </TransitionGroup>

                    <div style={{ textAlign: 'center' }}>
                      <button
                        type="button"
                        className={classNames(
                          'NewsList__read-more',
                          'Button btn btn-outline-dark btn-lg',
                          {
                            invisible: newsItemsRange > tab1.news.length,
                          }
                        )}
                        onClick={this.showNewsItems}
                      >
                        See older updates
                      </button>
                    </div>

                    <div id="resources">
                      {resources && (
                        <CardList
                          cards={resources.map(resource => ({
                            title: resource.title,
                            link: `/covid-19/${resource.slug.current}`,
                          }))}
                          heading="Resources"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              exact
              path="/covid-19/:resource/:slug"
              render={props => <DocumentTypeSwitcher {...props} />}
            />

            <Route
              exact
              path="/covid-19/:resource"
              render={({ match }) => {
                const { resource: resourceSlug } = match.params;
                const resource = resources.find(
                  resource => resource.slug.current === resourceSlug
                );

                if (!resource) return null;

                return (
                  <div
                    className="fade-in container CurrentAffairsPage"
                    style={{
                      marginBottom: '8rem',
                    }}
                  >
                    <PageHeader
                      kicker="Resources"
                      document={{
                        title: resource.title,
                      }}
                      plain
                    >
                      <Link
                        to="/covid-19"
                        className="NewsList__back"
                        onClick={() => {
                          setTimeout(() => {
                            const element = document.querySelector(
                              '#resources'
                            );
                            if (element) {
                              element.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }
                          }, 150);
                        }}
                      >
                        <svg
                          className="NewsList__back-arrow"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="109px"
                          height="162.4px"
                          viewBox="0 0 109 162.4"
                          xmlSpace="preserve"
                        >
                          <g transform="translate(0, -201)">
                            <g transform="translate(-139, -318)">
                              <polygon
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                points="193.5,680 247,626.4 247,599.5 203,643.7 203,520 184,520 184,643.7 140,599.5 140,626.4"
                              ></polygon>
                            </g>
                          </g>
                        </svg>
                        Back to all resources
                      </Link>
                    </PageHeader>

                    <TransitionGroup
                      className="NewsList row"
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      {Array.isArray(resource.news) &&
                        resource.news.map((item, index) => {
                          item.index = index + 1;

                          return (
                            <CSSTransition
                              key={item._id + index}
                              timeout={500}
                              classNames="fade"
                            >
                              <NewsItem {...item} fullWidth />
                            </CSSTransition>
                          );
                        })}
                    </TransitionGroup>
                  </div>
                );
              }}
            />
          </Switch>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <CurrentAffairsPage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
