import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { Link as ScrollLink, Events } from 'react-scroll';
import '../stylesheets/TheNavbar.css';

const isIE = (function() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  return msie > 0 || trident > 0;
})();

class TheNavbar extends Component {
  constructor(props) {
    super(props);
    this.navBar = React.createRef();
  }

  componentDidMount() {
    Events.scrollEvent.register('begin', (to, element) => {
      this.navBar.current.classList.remove('show');
    });
  }
  componentDidUpdate() {
    this.navBar.current.classList.remove('show');
  }

  render() {
    return (
      <nav
        className="navbar navbar-expand-xl navbar-light bg-white main-nav fixed-top"
        ref={this.navBar}
      >
        <div className="Navbar__container justify-content-center">
          <div
            className={classNames('nav navbar-nav flex-fill flex-nowrap', {
              'w-100': !isIE,
              'w-50': isIE,
            })}
          >
            <NavLink
              exact
              className="navbar-brand mr-auto"
              to="/"
              onTouchStart={e => {
                // For some reason this removes the need to double click
                // navigation links in Safari on iOS.
                e.preventDefault();
              }}
            >
              <img
                src="/img/iFranaLogo@2x.jpg"
                alt="Fragrance Creator Association logo"
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                this.navBar.current.classList.toggle('show');
              }}
              onTouchStart={e => {
                e.preventDefault();
              }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <ul className="nav navbar-nav flex-fill justify-content-center flex-nowrap">
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to="/about-us"
              >
                About us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about-fragrance">
                About
                <br />
                Fragrance
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about-the-industry">
                About the
                <br />
                Fragrance Industry
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/news">
                News
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/events">
                Events
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/member-center">
                Member
                <br />
                Center
              </NavLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="Contact"
                smooth={'easeInOutExpo'}
                offset={-100}
                duration={1000}
              >
                Contact us
              </ScrollLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link nav-link--search" to="/search">
                <svg width="24px" height="24px" viewBox="0 0 24 24">
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g transform="translate(-1470, -787)" fill="currentColor">
                      <g transform="translate(1100, 728)">
                        <g transform="translate(381.856444, 71.035503) scale(-1, 1) translate(-381.856444, -71.035503) translate(369.856444, 59.035503)">
                          <path d="M16.9923046,15.9314031 L16.5268902,16.475025 L15.9815478,16.9389711 C12.3181787,20.0338815 6.83061843,19.5823848 3.72508057,15.9314031 C0.620375305,12.2795914 1.07246835,6.80934343 4.73666998,3.71360306 C8.40003903,0.61869264 13.8875993,1.0693594 16.9923046,4.72200106 C19.7423292,7.95551433 19.7423292,12.6978898 16.9923046,15.9314031 L16.9923046,15.9314031 Z M18.2603299,17.0053677 C21.9611652,12.655562 21.4224834,6.13873801 17.0589113,2.45040819 C12.6953392,-1.23792163 6.15789066,-0.701769273 2.45788792,3.6488664 C-1.24211482,7.99867211 -0.703433056,14.5154961 3.66013903,18.2029959 C7.52499338,21.4705375 13.194057,21.4705375 17.0589113,18.2029959 L22.1076999,23.2366864 L23.3099511,22.0365683 L18.2603299,17.0053677 Z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </NavLink>
            </li>
          </ul>
          <ul className="nav navbar-nav flex-fill w-100 justify-content-end">
            <li className="nav-item push-down">
              <a
                className="nav-link nav-link--logo no-hover"
                href="http://fragrancenotes.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/fragrance-notes@2x.jpg"
                  style={{ maxHeight: '35px' }}
                  alt="Fragrance Notes logo"
                />
              </a>
            </li>
            <li className="nav-item push-down">
              <a
                className="nav-link nav-link--logo no-hover"
                href="https://fragranceconservatory.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/fragrance-conservatory.png"
                  style={{ maxHeight: '50px' }}
                  alt="Fragrance Notes logo"
                />
              </a>
            </li>
            <li className="d-md-none" style={{ textAlign: 'center' }}>
              <a
                href="https://www.facebook.com/FragranceCreators"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '15px' }}
              >
                <img src="/img/facebook.svg" alt="Facbook logo" width="30" />
              </a>
              <a
                href="https://www.instagram.com/fragrancecreators/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '15px' }}
              >
                <img src="/img/instagram.svg" alt="Instagram logo" width="30" />
              </a>
              <a
                href="https://twitter.com/Make_Scents"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '15px' }}
              >
                <img src="/img/twitter.svg" alt="Twitter logo" width="30" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(TheNavbar);
