import sanityClient from '@sanity/client'

const client = sanityClient({
  projectId: 'vzmfjgad',
  dataset: 'production',
  useCdn: true
})

const previewClient = sanityClient({
  projectId: 'vzmfjgad',
  dataset: 'production',
  useCdn: false,
  withCredentials: true
})

export {client, previewClient}
