import React from 'react'
import BaseImage from './BaseImage'
import BlockContent from '@sanity/block-content-to-react'

import '../stylesheets/BlockTextWithImage.css'

const fileSerializer = props => (
  <div>
    <a
      style={
        {
          textTransform: 'uppercase',
          color: '#000',
          fontWeight: '700'
        }
      }
      href={`${props.node.asset.url}?dl`}>Download</a>
  </div>
)

const serializers = {
  types: {
    file: fileSerializer
  }
}
const BlockTextWithImage = (props) => {
  const content = props.node || props
  return (
    <section className='BlockTextWithImage container'>
      <div className='row'>
        <div className='BlockTextWithImage__image col-md-5 offset-md-1'>
          <BaseImage
            image={content.blockImage}
            ratio={1}
            width={800}
          />
        </div>
        <div className='BlockTextWithImage__text col-md-6 col-lg-4 offset-md-1'>
          <BlockContent blocks={content.text || []} serializers={serializers} />
        </div>
      </div>
    </section>
  )
}

export default BlockTextWithImage
