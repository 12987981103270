import React from 'react';
import '../stylesheets/BlockDocument.css';

const openNewTabExtensions = ['pdf'];

const BlockDocument = props => {
  const hasFile = Boolean(
    props.node.file && props.node.file.asset && props.node.file.asset.url
  );
  const openNewTab =
    hasFile &&
    openNewTabExtensions.indexOf(props.node.file.asset.url.slice(-3)) !== -1;

  return (
    <div className="row">
      <div className="col-md-8">
        {hasFile ? (
          <a
            href={`${props.node.file.asset.url}${openNewTab ? '' : '?dl'}`}
            className="BlockDocument clearfix"
            target={openNewTab ? '_blank' : null}
            rel={openNewTab ? 'noopener noreferrer' : null}
          >
            <div className="icon"></div>
            <div className="text">
              <h5>{props.node.title}&nbsp;</h5>
              <p>{props.node.desc}</p>
            </div>
          </a>
        ) : (
          <span className="BlockDocument clearfix">
            <div className="icon"></div>
            <div className="text">
              <h5>File missing</h5>
            </div>
          </span>
        )}
      </div>
    </div>
  );
};

export default BlockDocument;
