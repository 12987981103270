import React from 'react';
import BaseImage from './BaseImage';
// import '../stylesheets/BlockImage.css'

const BlockImage = props => {
  if (props.node.asset) {
    return (
      <div className="row">
        <div className="col-12">
          <div style={{ margin: '1rem 0 2rem' }}>
            <BaseImage
              image={props.node.asset}
              node={props.node}
              width={1400}
            />
            <p>
              <small>{props.node.caption}</small>
            </p>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default BlockImage;
