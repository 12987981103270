import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Scroll from 'react-scroll';
import shuffle from 'lodash/shuffle';
import { withRouter } from 'react-router-dom';
import MemberItem from '../components/MemberItem';
import PageHeaderWithVideo from '../components/PageHeaderWithVideo';
import NewsItem from '../components/NewsItem';
import Metadata from '../components/Metadata';
import BlockLink from '../components/BlockLink';

class FrontPage extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
    const hash = this.props.location.hash;

    if (hash !== '') {
      Scroll.scroller.scrollTo(hash.replace('#', ''), {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -100,
      });
    }

    document.body.classList.add('frontpage');
  }

  componentWillUnmount() {
    document.body.classList.remove('frontpage');
  }

  render() {
    const { news, events, members, isLoading, linkBlocks, header } = this.props;

    return !isLoading ? (
      <article>
        <Metadata
          title="Fragrance Creators Association"
          description="Fragrance has the power – beyond all frontiers and cultures – to enhance lives."
          image={null}
          location={this.props.location}
        />
        {header && <PageHeaderWithVideo {...header} />}
        <div style={{ overflowX: 'hidden' }} className="container">
          {linkBlocks &&
            linkBlocks.map((item, index) => {
              item.index = index;
              return <BlockLink {...item} key={item._key} />;
            })}
          {news && news.length > 0 && (
            <section style={{ marginBottom: '8em' }}>
              <h2 className="display-4">News</h2>
              <TransitionGroup className="row NewsList">
                {news.map((item, index) => {
                  item.index = index;
                  item.featured = index === 0;

                  return (
                    <CSSTransition
                      key={item._id + index}
                      timeout={500}
                      classNames="fade"
                    >
                      <NewsItem {...item} />
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
              <div style={{ textAlign: 'center' }}>
                <Link
                  to="/news#5"
                  className={'Button btn btn-outline-dark btn-lg'}
                >
                  Show more news
                </Link>
              </div>
            </section>
          )}
          {events && events.length > 0 && (
            <section style={{ marginBottom: '8em' }}>
              <h2 className="display-4">Events</h2>
              <TransitionGroup className="row NewsList">
                {events.map((item, index) => {
                  item.index = index;
                  item.featured = true;

                  return (
                    <CSSTransition
                      key={item._id + index}
                      timeout={500}
                      classNames="fade"
                    >
                      <NewsItem {...item} />
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
              <div style={{ textAlign: 'center' }}>
                <Link
                  to="/events#2"
                  className={'Button btn btn-outline-dark btn-lg'}
                >
                  Show more events
                </Link>
              </div>
            </section>
          )}
          {members && (
            <section className="row" style={{ marginBottom: '8rem' }}>
              <div className="col-12">
                <h2 className="display-4">Our members</h2>
              </div>
              {shuffle(members)
                .slice(0, 6)
                .map(member => (
                  <MemberItem {...member} key={member._id} />
                ))}
              <div
                className="col-12"
                style={{ textAlign: 'center', marginTop: '4rem' }}
              >
                <Link
                  className="Button btn btn-lg btn-outline-dark"
                  to="/member-center"
                >
                  Go to member center
                </Link>
              </div>
            </section>
          )}
        </div>
      </article>
    ) : (
      <Loader />
    );
  }
}

export default withRouter(FrontPage);
