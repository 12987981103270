import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//import BaseImage from './BaseImage';

const InternalLink = ({ _type, slug, children, ...props }) => {
  let root = '/';

  if (_type === 'currentAffairsArticle') {
    root = '/covid-19/resource/';
  }

  const to = `${root}${slug ? slug.current : ''}`;

  return (
    <RouterLink {...props} to={to}>
      {children}
    </RouterLink>
  );
};

export default InternalLink;
