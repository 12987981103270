import React, { Component } from 'react'
import Person from './Person'
import '../stylesheets/BlockGroup.css'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class Group extends Component {
  state = {
    displayRange: 2
  }

  displayMore = () => {
    this.setState({
      displayRange: this.props.node.members.length
    })
  }

  render () {
    const { title, members} = this.props.node
    const { displayRange } = this.state
    let isOdd = true

    return (
      <section className='BlockGroup'>
        <h3>{ title || '' }</h3>
        <TransitionGroup>
          {
            members.slice(0, displayRange).map((member, index) => {
              isOdd = !isOdd
              return (member.person && (
                <CSSTransition
                  key={member.person._id}
                  timeout={500}
                  classNames='fade'>
                  <Person {...member} isOdd={isOdd} />
                </CSSTransition>
              ))
            })
          }
        </TransitionGroup>
        {
          // Show more button?
          members.length > displayRange && (
            <div className='col-12 BlockGroup__buttonContainer'>
              <button
                className='Button btn btn-outline-dark btn-lg'
                onClick={() => this.displayMore()}>
                { `Show all ${title || ''}` }
              </button>
            </div>
          )
        }
      </section>
    )
  }
}

export default Group
