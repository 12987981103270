import React from 'react';
import classNames from 'classnames';
import BaseImage from './BaseImage';
import BlockContent from '@sanity/block-content-to-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Vector from './Vector';

import '../stylesheets/NewsItem.css';

const NewsItem = ({
  _type,
  featured,
  index,
  slug,
  fullWidth,
  externalLink,
  ...props
}) => {
  let root = '/';
  if (_type === 'currentAffairsArticle') {
    root = '/covid-19/resource/';
  }

  const link = `${root}${slug ? slug.current : ''}`;

  if (fullWidth) {
    return (
      <article
        id={`NewsItem-${index}`}
        className={classNames('NewsItem NewsItem--fullWidth')}
      >
        {featured && (
          <div className={classNames('NewsItem__image')}>
            {props.headerImage && (
              <>
                {externalLink ? (
                  <a
                    href={externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BaseImage
                      image={props.headerImage}
                      ratio={1.3}
                      width={400}
                    />
                  </a>
                ) : (
                  <Link to={link}>
                    <BaseImage
                      image={props.headerImage}
                      ratio={1.3}
                      width={400}
                    />
                  </Link>
                )}
              </>
            )}
          </div>
        )}
        <div className={classNames('NewsItem__content')}>
          {externalLink ? (
            <a href={externalLink} target="_blank" rel="noopener noreferrer">
              <p>
                <small>
                  {moment(props.startTime || props._publishedAt).format(
                    'MMMM D, YYYY'
                  )}
                </small>
              </p>
              <h4>{props.title}</h4>
              <BlockContent blocks={props.lead || []} />
              <p className="read-more">
                READ MORE <Vector type="externalLink" />
              </p>
            </a>
          ) : (
            <Link to={link}>
              <p>
                <small>
                  {moment(props.startTime || props._publishedAt).format(
                    'MMMM D, YYYY'
                  )}
                </small>
              </p>
              <h4>{props.title}</h4>
              <BlockContent blocks={props.lead || []} />
              <p className="read-more">READ MORE</p>
            </Link>
          )}
        </div>
      </article>
    );
  }

  const linkInner = (
    <>
      <p>
        <small>
          {moment(props.startTime || props._publishedAt).format('MMMM D, YYYY')}
        </small>
      </p>
      <h4>{props.title}</h4>
      <BlockContent blocks={props.lead || []} />
      <p className="read-more">
        READ MORE {externalLink && <Vector type="externalLink" />}
      </p>
    </>
  );
  return (
    <article
      id={`NewsItem-${index}`}
      className={classNames('NewsItem', {
        'col-md-12': featured,
        'col-md-6': !featured,
      })}
    >
      {featured && (
        <div
          className={classNames('NewsItem__image  col-md-4 offset-md-1', {
            'order-md-last': index % 2 === 1,
          })}
        >
          {props.headerImage && (
            <>
              {externalLink ? (
                <a
                  href={externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BaseImage
                    image={props.headerImage}
                    ratio={1.3}
                    width={400}
                  />
                </a>
              ) : (
                <Link to={link}>
                  <BaseImage
                    image={props.headerImage}
                    ratio={1.3}
                    width={400}
                  />
                </Link>
              )}
            </>
          )}
        </div>
      )}
      <div
        className={classNames('NewsItem__content', {
          'col-md-6': featured,
          // 'offset-md-2': index % 2 === 1 && featured,
          'order-md-last offset-md-1': index % 2 === 0 && featured,
          'ml-md-3': index % 2 === 0 && !featured,
          'mr-md-3': index % 2 === 1 && !featured,
        })}
      >
        {externalLink ? (
          <a href={externalLink} target="_blank" rel="noopener noreferrer">
            {linkInner}
          </a>
        ) : (
          <Link
            to={link}
            className={classNames({
              'ml-md-3': index % 2 === 0 && featured,
            })}
          >
            {linkInner}
          </Link>
        )}
      </div>
    </article>
  );
};

export default NewsItem;
