import React from 'react'
import BaseImage from './BaseImage'
import '../stylesheets/MemberItem.css'

const MemberItem = (props) => {
  return (
    <div className='col-md-2 col-6'>
      <a href={props.websiteUrl || '/'}
        target='_blank'
        rel='noopener noreferrer'
        className='MemberItem'>
        {
          props.logo &&
            <BaseImage
              image={props.logo}
              ratio={1}
              width={400}
            />
        }
      </a>
    </div>
  )
}

export default MemberItem
