import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Scroll from 'react-scroll';
import moment from 'moment';

import { client, previewClient } from './utils/sanityClient';

import DocumentTypeSwitcher from './pages/DocumentTypeSwitcher';
import FrontPage from './pages/FrontPage';
import FourOFour from './pages/404';
import MembersPage from './pages/MembersPage';
import NewsPage from './pages/NewsPage';
import EventsPage from './pages/EventsPage';
import SearchPage from './pages/SearchPage';
import CurrentAffairsPage from './pages/CurrentAffairsPage';

import TheNavbar from './components/TheNavbar';
import BlockTextWithImage from './components/BlockTextWithImage';
import SocialLinks from './components/SocialLinks';
import CtaBadge from './components/CtaBadge';

import './App.css';

const SanityContext = React.createContext();
const URLSearchParams = require('url-search-params');

class App extends Component {
  state = {};

  constructor() {
    super();

    this.initSanity();
  }

  initSanity() {
    // We need to use the preview client to
    // fetch unpublished content. We use context to pass
    // the proper client to children.

    const params = new URLSearchParams(global.location.search);
    const isPreview = params.get('preview');
    this.sanityClient = isPreview ? previewClient : client;

    // On first load get contact information
    // from the frontpage document in Sanity
    const query = `*[_id == '5c8bef24-c38c-4cbb-87d8-85d0d031d05c'] {
      contactInfo,
      linkBlocks,
      header {
        ...,
        video {
          asset->{url}
        }
      },
      'news': *[_type == 'news']{
        title,
        slug,
        _id,
        _createdAt,
        publishedAt,
        "_publishedAt": coalesce(publishedAt, _createdAt),
        lead,
        headerImage
      } | (order publishedAt desc) [0..4],
      'events': *[_type == 'event' && startTime > '${moment().toISOString()}']{
        title,
        slug,
        _id,
        startTime,
        lead,
        headerImage
      } | (order startTime asc) [0..1],
      'members': *[_type == 'member']{...}[0..100],
      'currentAffairs': *[_type == 'currentAffairsPage'][0..0],
    }[0]`;

    this.sanityClient
      .fetch(query)
      .then(result => {
        if (Array.isArray(result.currentAffairs)) {
          result.currentAffairs = result.currentAffairs[0];
        }
        this.setState(result);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { contactInfo, currentAffairs } = this.state;

    return (
      <SanityContext.Provider value={{ client: this.sanityClient }}>
        <div>
          <TheNavbar />
          <SocialLinks />
          <main>
            <Switch>
              <Route
                path="/"
                exact
                render={props => <FrontPage {...props} {...this.state} />}
              />
              <Route path="/news" component={NewsPage} />
              <Route path="/events" component={EventsPage} />
              <Route path="/search/:query?" component={SearchPage} />
              <Route path="/covid-19" component={CurrentAffairsPage} />
              <Route path="/members" component={MembersPage} />
              <Route path="/404" component={FourOFour} />
              <Route
                path="/current"
                render={() => <Redirect to="/covid-19" />}
              />

              {
                // For miscPage, event entries, news entries
                // Uing type switcher to get a flat URL structure.
                // It looks at the document type from Sanity and assigns a component accordingly.
              }
              <Route
                path="/:slug"
                render={props => <DocumentTypeSwitcher {...props} />}
              />
            </Switch>
          </main>
          {currentAffairs && currentAffairs.cta && currentAffairs.cta.show && (
            <CtaBadge
              text={currentAffairs.cta.text}
              button={currentAffairs.cta.button}
              link={`/current`}
            />
          )}
          {contactInfo && (
            <footer>
              <Scroll.Element name="Contact">
                <div className="col-12">
                  <h2 className="display-4">Contact</h2>
                </div>
                <BlockTextWithImage {...contactInfo} />
              </Scroll.Element>
            </footer>
          )}
        </div>
      </SanityContext.Provider>
    );
  }
}

const AppWithRouter = withRouter(App);

export { AppWithRouter, SanityContext };
