/**
 * This file is part of the FCA 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';

/**
 * This is the Vector component.
 *
 * @author Bjørn Fjellstad <bjorn@apt.no>
 *
 * @return {JSX}
 */
function Vector({ type, className, ...rest }) {
  const commonProps = {
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    className,
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
  };

  switch (type) {
    case 'externalLink':
      return (
        <svg
          height="14px"
          width="14px"
          viewBox="0 0 100 100"
          {...commonProps}
          fill="currentColor"
        >
          <path d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path>
          <polygon points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9 "></polygon>
        </svg>
      );
    default:
      return null;
  }
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Vector.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Vector.defaultProps = {
  className: '',
};

export default Vector;
