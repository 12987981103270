import React from 'react'
import { Link } from 'react-router-dom'
import BaseImage from './BaseImage'

import '../stylesheets/BlockLink.css'

const BlockLink = (props) => {
  let isOdd = props.index % 2 === 1

  return (
    <Link to={props.link || '/'} className='BlockLink row'>
      <div
        className={'BlockLink__text col-md-5 offset-md-1 ' +
        (isOdd ? 'order-md-last' : '')}
      >
        <h2>{props.title || ''}</h2>
        <p>{props.lead || ''}</p>
        {
          props.link && <p className='read-more'>READ MORE</p>
        }
      </div>
      <div
        className={'BlockLink__image col-md-5 ' +
          (isOdd ? '' : 'order-md-last offset-md-1')}>
        {
          props.thumbnail &&
            <BaseImage
              image={props.thumbnail}
              ratio={1}
              width={1000} />
        }
      </div>
    </Link>
  )
}

export default BlockLink
