import React, { Component } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import BlockGroup from '../components/BlockGroup';
import BlockTextWithImage from '../components/BlockTextWithImage';
import Loader from '../components/Loader';
import PageHeader from '../components/PageHeader';
import PageHeaderWithVideo from '../components/PageHeaderWithVideo';
import BlockTextTwoColumns from '../components/BlockTextTwoColumns';
import Metadata from '../components/Metadata';
import BlockMembers from '../components/BlockMembers';
import { SanityContext } from '../App';

import '../stylesheets/MiscPage.css';

const BlockRenderer = props => {
  const style = props.node.style || 'normal';
  const wrap = content => {
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">{content}</div>
      </div>
    );
  };
  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '');
    return wrap(
      <h2 className={`my-heading level-${level}`}>{props.children}</h2>
    );
  }

  return style === 'blockquote'
    ? wrap(<blockquote className="my-block-quote">{props.children}</blockquote>)
    : wrap(<p style={{ textAlign: 'center' }}>{props.children}</p>);
};

const serializers = {
  types: {
    block: BlockRenderer,
    group: BlockGroup,
    blockTextWithImage: BlockTextWithImage,
    blockTextTwoColumns: BlockTextTwoColumns,
    members: BlockMembers,
  },
};

class MiscPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
    };
  }

  componentDidMount() {
    this.getData(this.props.id);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // Avoid fetching the previous document
    if (this.props.id !== prevProps.id) {
      this.getData(this.props.id);
      window.scrollTo(0, 0);
    }
  }

  getData = id => {
    const { sanityClient } = this.props;
    let query = `
      *[_id == '${id}']{
      ...,
      headerVideo {
        asset->{url}
      },
      body[] {
        ...,
        members[] {
          role,
          person->{...}
        },
        text[] {
          ...,
          asset->{url}
        }
      }
    }[0]
    `;

    sanityClient
      .fetch(query)
      .then(result => {
        this.setState({
          document: result,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { document } = this.state;
    return (
      <article>
        {document ? (
          <React.Fragment>
            <Metadata
              title={document.title}
              description={document.lead}
              image={document.headerImage}
              location={this.props.location}
            />
            {document.headerVideo && document.headerVideo.asset ? (
              <PageHeaderWithVideo
                video={document.headerVideo}
                image={document.headerImage}
                lead={document.lead || []}
                title={document.title}
              />
            ) : (
              <PageHeader document={document} />
            )}
            <div className="MiscPage container">
              <div className="BlockContent">
                <BlockContent
                  blocks={document.body || []}
                  serializers={serializers}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </article>
    );
  }
}

export default props => (
  <SanityContext.Consumer>
    {context => <MiscPage {...props} sanityClient={context.client} />}
  </SanityContext.Consumer>
);
