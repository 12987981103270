import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

import '../stylesheets/BlockTextTwoColumns.css'

const BlockTextTwoColumns = (props) => {
  const content = props.node || props
  return (
    <section className="container">
      <div className="row BlockTextTwoColumns">
        <div className="col-md-3 offset-md-2">
          <BlockContent blocks={content.text.slice(0, content.text.length / 2)} />
        </div>
        <div className="col-md-3 offset-md-2 is-right">
          <BlockContent blocks={content.text.slice(content.text.length / 2, -1)} />
        </div>
      </div>
    </section>
  )
}

export default BlockTextTwoColumns
