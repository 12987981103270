import React from 'react'
import Metadata from '../components/Metadata'
const FourOFour = () => (
  <div className='row' style={{height: '50vh', paddingTop: '10vh'}}>
    <Metadata
      status={404}
      />
    <div className="col-12">
      <h1 style={{textAlign: 'center'}}>Page not found</h1>
    </div>
  </div>
)

export default FourOFour
