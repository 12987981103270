import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InternalLink from './InternalLink';
import Vector from './Vector';

import '../stylesheets/Timeline.scss';

const Timeline = ({ entries }) => {
  const [openEntry, setOpenEntry] = useState(entries.length - 1);

  const scrollContainerRef = useRef();

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.clientWidth;
    }
  }, []);

  return (
    <div className="Timeline" ref={scrollContainerRef}>
      <ul className="Timeline__entries">
        {entries.map((entry, i) => {
          let inner = (
            <>
              <h3 className="Timeline__date">{entry.date}</h3>
              <p className="Timeline__text">
                {entry.text}{' '}
                {(entry.internalLink || entry.externalLink) && (
                  <strong>
                    &nbsp;Read more{' '}
                    {entry.externalLink && <Vector type="externalLink" />}
                  </strong>
                )}
              </p>
            </>
          );

          if (entry.externalLink) {
            inner = (
              <a
                className="Timeline__link"
                href={entry.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {inner}
              </a>
            );
          } else if (entry.internalLink) {
            if (entry.internalLink._type === 'currentAffairsArticle')
              inner = (
                <InternalLink
                  className="Timeline__link"
                  {...entry.internalLink}
                >
                  {inner}
                </InternalLink>
              );
          }

          return (
            <li
              key={i}
              className={classNames('Timeline__entry', {
                'Timeline__entry--open': openEntry === i,
              })}
              onClick={() => setOpenEntry(i)}
              onMouseEnter={() => setOpenEntry(i)}
            >
              {inner}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Timeline.prototypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

Timeline.defaultProps = {
  entries: [],
};

export default Timeline;
