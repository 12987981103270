import React from 'react';

import { Link } from 'react-router-dom';

import '../stylesheets/CtaBadge.scss';

const CtaBadge = ({ text, button, link }) => (
  <div to={link} className="CtaBadge">
    <Link to={link} className="CtaBadge__link">
      <p className="CtaBadge__text">{text}</p>
      <span className="CtaBadge__button">{button}</span>
    </Link>
    {/* <button
      type="button"
      className="CtaBadge__close"
      onClick={e => {
        e.stopPropagation();
        document.body.classList.add('hide-badge');
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button> */}
  </div>
);

export default CtaBadge;
